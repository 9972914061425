import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageRender from "./customRouter/PageRender";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";

function App() {
  const [show, setShow] = useState(false);

  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Router>
      <div className="font-manrope">
        <Navbar show={show} setShow={setShow} scrollToForm={scrollToForm} />
        <Routes>
          <Route
            exact
            path="/"
            element={<Home formRef={formRef} scrollToForm={scrollToForm} />}
          />
          <Route exact path="/:page" element={<PageRender />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
